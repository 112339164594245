import React, { useEffect, useMemo } from 'react';

import type { SectionPageProps } from './types.js';
import type { CuratePulseTrackingCustomProperties } from '../../../../core/utils/types.js';

import Navigation from '../../components/commonComponents/Navigation/Navigation.js';
import { splitToChunks } from '../front/frontHelpers.js';
import Teaser from '../../components/teasers/Teaser.js';
import BundleDesktop from '../../components/bundles/BundleDesktop.js';
import Footer from '../../components/commonComponents/Footer.js';
import BundleMobile from '../../components/bundles/BundleMobile.js';
import { ListsProvider } from '../../components/saving/ListsProvider.js';
import { useSessionData } from '../../../../../public-src/core/js/schibsted-account/react.server.js';
import useInitializeAds from '../../hooks/useInitAds.js';
import { usePulseContext } from '../../../../core/mandatory-integrations/pulse/PulseProvider.js';
import { trackSectionPageView, withByfunnPulse } from '../../../pulse/index.js';
import Ad from '../../components/Ad/Ad.js';
import { insertAdsPlacements } from '../../components/Ad/helpers.js';
import { shouldCompressBundle } from '../../components/bundles/utils.js';

const SectionPage: React.FC<SectionPageProps> = ({
  options,
  isMobile,
  response,
  isHermesApp,
}) => {
  const { currentUrl, siteVersion } = options;

  const activeSection = currentUrl.split(/[?#]/)[0].substring(1);
  const sectionName = activeSection.split('/').pop();
  let mobileRows = 1;

  const { registerViewElementTracking, trackViewListing, getEnvironmentId } =
    usePulseContext();

  const personalization: CuratePulseTrackingCustomProperties = useMemo(
    () => ({
      variant: response.properties.id,
      removeOnRead: response.personalization.removeOnRead,
      removeOnImpressed: response.personalization.removeOnImpressed,
      personalizedResults: response.personalization.personalizedResults,
    }),
    [
      response.properties.id,
      response.personalization.removeOnRead,
      response.personalization.removeOnImpressed,
      response.personalization.personalizedResults,
    ],
  );

  useEffect(() => {
    sectionName &&
      trackSectionPageView(trackViewListing, sectionName, personalization);

    registerViewElementTracking({
      object: {
        page: {
          id: 'section-page',
          type: 'Listing',
        },
      },
    });
  }, [
    trackViewListing,
    sectionName,
    personalization,
    registerViewElementTracking,
  ]);

  const session = useSessionData();
  useInitializeAds(getEnvironmentId, session, siteVersion);

  const chunks = splitToChunks(response.items);
  const chunksWithAds = insertAdsPlacements(
    isMobile,
    chunks,
    'frontpage',
    'frontpage',
  );

  return (
    <ListsProvider>
      <div className={`${siteVersion}-front section`}>
        <Navigation
          fullWidth={!isMobile}
          activeSection={sectionName}
          isSticky
          isHermesApp={isHermesApp}
        />

        {chunksWithAds.map((c, i): React.ReactNode => {
          const index = isMobile && i > 0 ? mobileRows : i + 1;

          switch (c.type) {
            case 'teaser':
              if (c.fullWidthTeaser) {
                mobileRows++;

                return <Teaser key={`teaser-${index}`} {...c} index={index} />;
              }

              return <></>;
            case 'chunk':
            case 'bundle':
              mobileRows += shouldCompressBundle(c, isMobile)
                ? 2
                : c.items.length;

              return isMobile ? (
                <BundleMobile key={`chunk-${index}`} {...c} index={index} />
              ) : (
                <BundleDesktop key={`chunk-${index}`} {...c} index={index} />
              );

            case 'ad':
              mobileRows++;

              return (
                <Ad
                  key={`Ad-${c.id}`}
                  id={c.id}
                  cssClass={c.cssClass}
                  isMobile={isMobile}
                />
              );
            default:
              return <></>;
          }
        })}
        <Footer isMobile={isMobile} />
      </div>
    </ListsProvider>
  );
};

const SectionPageWithPulse = withByfunnPulse(SectionPage);

export { SectionPageWithPulse as SectionPage };
